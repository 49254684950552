<template>
  <v-app class="app-container">
    <v-main>
      <router-view />
    </v-main>
    <!-- Bottom Navigation -->
    <v-container>
      <v-bottom-navigation
        v-if="showBottomNav"
        v-model="activeTab"
        app
        fixed
        color="light-blue accent-4"
        class="bottom_nav"
      >
        <!-- hide-on-scroll -->
        <v-btn
          style="border-top: 1px solid #0091ea; font-weight: bold"
          icon
          @click="navigateTo(0)"
        >
          หน้าหลัก
          <v-icon>mdi-home</v-icon>
        </v-btn>

        <v-btn
          style="border-top: 1px solid #0091ea; font-weight: bold"
          icon
          @click="navigateTo(1)"
        >
          ดูโพย
          <v-icon>mdi-file-document</v-icon>
        </v-btn>
        <v-btn
          style="border-top: 1px solid #0091ea; font-weight: bold"
          icon
          @click="navigateTo(2)"
        >
          ลูกค้า
          <v-icon>mdi-account-multiple</v-icon>
        </v-btn>

        <v-btn
          style="border-top: 1px solid #0091ea; font-weight: bold"
          icon
          @click="navigateTo(3)"
        >
          รายงาน
          <v-icon>mdi-finance</v-icon>
        </v-btn>

        <v-btn
          style="border-top: 1px solid #0091ea; font-weight: bold"
          icon
          @click="navigateTo(4)"
        >
          ตั้งค่า
          <v-icon>mdi-cogs</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    this.showBottomNav = this.allowedPaths.includes(this.$route.name);

    const index = this.Path.indexOf(this.$route.path);

    if (index !== -1) {
      //show current
      this.activeTab = index;
    }
  },
  watch: {
    // ใช้ watcher เพื่อตรวจสอบ path ปัจจุบันของ Vue Router
    $route(to, from) {
      //   window.history.forward(1);
      console.log("path=" + to.name);
      // ตรวจสอบ path ปัจจุบันและกำหนดค่า showBottomNav ตามที่คุณต้องการ
      //if() CusID
      this.showBottomNav = this.allowedPaths.includes(to.name);
      switch (to.name) {
        case "Dashboard":
          this.activeTab = 0;
          break;
        case "Pages":
          this.activeTab = 1;
          break;
        case "Customers":
          this.activeTab = 2;
          break;
        case "Reports":
          this.activeTab = 3;
          break;
        case "Setting":
          this.activeTab = 4;
          break;
        default:
          break;
      }
      //   console.log(this.allowedPaths.includes(to.name));
    },
  },
  mounted() {
    const index = this.Path.indexOf(this.$route.path);

    if (index !== -1) {
      //show current
      this.activeTab = index;
    }
    console.log("this.activeTab");
    console.log(this.activeTab);
  },
  methods: {
    navigateTo(p_index) {
      //ถ้า path เดิม
      //console.log("Current Page: ", this.$route.path);
      if (this.$route.path === this.Path[p_index]) return;
      this.$router.push(this.Path[p_index]);
    },
  },
  data: () => ({
    activeTab: 0,
    showBottomNav: false,
    allowedPaths: ["Dashboard", "Pages", "Customers", "Reports", "Setting"], // กำหนด path ที่ต้องการให้แสดง Bottom Navigation
    Path: ["/Dashboard", "/Pages", "/Customers", "/Reports", "/Setting"],
  }),
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;

  font-size: 3.8vw; /* ให้ขนาด font เป็น 2% ของความกว้างของหน้าจอ */
  //   letter-spacing: 0.0000000003em !important;
  // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  color: black;
}
.app-container {
  background: linear-gradient(
    135deg,
    #d0faee,
    #dcf3e5,
    #e7ecdc,
    #f3e5d3,
    #ffdeca
  );
}
.bottom_nav {
  background: linear-gradient(
    270deg,
    #ffdeca,
    #f3e5d3,
    #e7ecdc,
    #dcf3e5,
    #d0faee
  );
}
</style>

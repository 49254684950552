const state = {
  CusList: [],
  CusAllCount: 0,
  CusBanCount: 0,
  PriceSum: 0,
  PriceLoy: 0,
  SendPage: 0,
  NonSendPage: 0,
  ReturnPage: 0,
};

const getters = {
  CusList: (state) => state.CusList,
  CusAllCount: (state) => state.CusAllCount,
  CusBanCount: (state) => state.CusBanCount,
  PriceSum: (state) => state.PriceSum,
  PriceLoy: (state) => state.PriceLoy,
  SendPage: (state) => state.SendPage,
  NonSendPage: (state) => state.NonSendPage,
  ReturnPage: (state) => state.ReturnPage,
};

const mutations = {
  setCusList: (state, CusList) => {
    state.CusList = JSON.parse(JSON.stringify(CusList));
  },
  setCusAllCount: (state, CusAllCount) => {
    state.CusAllCount = CusAllCount;
  },
  setCusBanCount: (state, CusBanCount) => {
    state.CusBanCount = CusBanCount;
  },
  setPriceSum: (state, PriceSum) => {
    state.PriceSum = PriceSum;
  },
  setPriceLoy: (state, PriceLoy) => {
    state.PriceLoy = PriceLoy;
  },
  setSendPage: (state, SendPage) => {
    state.SendPage = SendPage;
  },
  setNonSendPage: (state, NonSendPage) => {
    state.NonSendPage = NonSendPage;
  },
  setReturnPage: (state, ReturnPage) => {
    state.ReturnPage = ReturnPage;
  },
  setSuspend: (state, CusID) => {
    state.CusList.find((o) => o.CusID === CusID)["Status"] = 0;
  },
  setUnlock: (state, CusID) => {
    state.CusList.find((o) => o.CusID === CusID)["Status"] = 1;
  },

  //   let obj = state.LineList.find((o) => o.LineNumber === LineNumber);
  //   state.LineList.splice(obj.id - 1, 1);
  //   for (let i = 0; i < state.LineList.length; i++) {
  //     state.LineList[i]["id"] = i + 1;
  //   }
};

const actions = {
  TransferData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "dashboard":
                  context.commit("setCusAllCount", data.response.CUS_ALL_COUNT);
                  context.commit("setCusBanCount", data.response.CUS_BAN_COUNT);

                  context.commit("setPriceSum", data.response.PriceSum);
                  context.commit("setPriceLoy", data.response.PriceLoy);
                  context.commit("setSendPage", data.response.SendPage);
                  context.commit("setNonSendPage", data.response.NonSendPage);
                  context.commit("setReturnPage", data.response.ReturnPage);

                  break;
                case "get_all":
                  console.log("get_all");
                  //   context.commit(
                  //     "setHPGroupRateList",
                  //     data.response.HPGroupRateList
                  //   );
                  context.commit("setCusList", data.response.CusList);
                  break;
                case "add":
                  context.commit("setCusList", data.response.CusList);
                  break;
                case "get_all_bet_data":
                  context.commit("setCusList", data.response.CusList);
                  break;
                case "suspend":
                  context.commit("setSuspend", data.response.CusID);
                  break;
                case "unlock":
                  context.commit("setUnlock", data.response.CusID);
                  break;

                default:
                  break;
              }
              resolve(data.response);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const cus = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

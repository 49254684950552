const state = {

};

const getters = {};

const mutations = {};

const actions = {
  TransferData(context, credentials) {
    console.log(credentials);

    // this._vm.$headers = {
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    //     Authorization: "Bearer ",
    //   },
    // };

    // console.log(this._vm.$headers);

    // // axios.request();
    // console.log("this._vm");
    // console.log(this._vm);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "request_connect":
                  localStorage.clear();
                  sessionStorage.clear();
                  localStorage.setItem("fun_token", data.response.token);
                  sessionStorage.setItem("HostID", data.response.HostID);
                  sessionStorage.setItem("LineID", data.response.LineID);
                  sessionStorage.setItem("AgentID", data.response.AgentID);
                  sessionStorage.setItem("PeriodDT", data.response.PeriodDT);

                  break;
                case "create_pin":
                  break;
                case "check_token":
                  break;
                case "check_pin":
                  sessionStorage.setItem("HostID", data.response.HostID);
                  sessionStorage.setItem("LineID", data.response.LineID);
                  sessionStorage.setItem("AgentID", data.response.AgentID);
                  sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                  break;
                default:
                  break;
              }
              //   console.log(data);
              //   context.commit("loginSuccess", data.response.user);

              //   resolve(data.response.Pincode);
              //   localStorage.removeItem("blog_token");
              //   //save token
              //   localStorage.setItem("blog_token", data.response.token);
              //   //save host_id
              //   sessionStorage.removeItem("blog_token");
              //   sessionStorage.setItem("host_id", data.response.user.id);
              //   //save round
              //   sessionStorage.setItem("period", data.response.period);
              //   //save nickname
              //   sessionStorage.setItem("nickname", data.response.user.nickname);

              //   this._vm.$headers = {
              //     headers: {
              //       "Content-Type":
              //         "application/x-www-form-urlencoded; charset=UTF-8",
              //       Authorization: "Bearer " + localStorage.getItem("blog_token"),
              //     },
              //   };
              resolve(data.response);
            } else {
              //console.log(data);
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

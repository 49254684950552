const state = {
  HPList: [],
  NonSendPage: 0,
  SendPage: 0,
  PriceSumAll: 0,
  PriceLoyAll: 0,
  //   HPGroupRateList: [],
};

const getters = {

};

const mutations = {

};

const actions = {
  TransferData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "get_list":
                //   console.log("get_dashboard");
                //   //   context.commit(
                //   //     "setHPGroupRateList",
                //   //     data.response.HPGroupRateList
                //   //   );
                //   context.commit("setHPList", data.response.HPList);
                //   context.commit("setPageStatus", data.response.PageStatus);

                  break;
                // case "request_connect":
                //   localStorage.setItem("fun_token", data.response.token);
                //   sessionStorage.setItem("HostID", data.response.HostID);
                //   sessionStorage.setItem("LineID", data.response.LineID);
                //   sessionStorage.setItem("AgentID", data.response.AgentID);
                //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                //   break;
                // case "create_pin":
                //   break;
                // case "check_token":
                //   break;
                // case "check_pin":
                //   sessionStorage.setItem("HostID", data.response.HostID);
                //   sessionStorage.setItem("LineID", data.response.LineID);
                //   sessionStorage.setItem("AgentID", data.response.AgentID);
                //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                //   break;
                default:
                  break;
              }
              resolve(data.response);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const periods = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

import Vue from 'vue'
import VueRouter from 'vue-router'
import SplashScreen from "../views/MainPages/SplashScreen.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SplashScreen',
    component: SplashScreen
  },
  {
    path: "/Connector",
    name: "Connector",
    component: () => import("../views/MainPages/Connector.vue"),
  },
  {
    path: "/PinCode",
    name: "PinCode",
    component: () => import("../views/MainPages/PinCode.vue"),
  },
  {
    path: "/CreatePinCode",
    name: "CreatePinCode",
    component: () => import("../views/MainPages/CreatePinCode.vue"),
  },
  {
    path: "/ChangePinCode",
    name: "ChangePinCode",
    component: () => import("../views/Setting/ChangePinCode.vue"),
  },
  {
    path: "/SelectPeriod",
    name: "SelectPeriod",
    component: () => import("../views/Home/SelectPeriod.vue"),
  },
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: () => import("../views/Home/Dashboard.vue"),
  },
  {
    path: "/Pages",
    name: "Pages",
    component: () => import("../views/Home/Pages.vue"),
  },
  {
    path: "/Customers",
    name: "Customers",
    component: () => import("../views/Home/Customers.vue"),
  },
  {
    path: "/Reports",
    name: "Reports",
    component: () => import("../views/Home/Reports.vue"),
  },
  {
    path: "/Setting",
    name: "Setting",
    component: () => import("../views/Home/Setting.vue"),
  },
  {
    path: "/Page",
    name: "Page",
    component: () => import("../views/Pages/Page.vue"),
  },
  {
    path: "/SharePage",
    name: "SharePage",
    component: () => import("../views/Pages/SharePage.vue"),
  },
  {
    path: "/KeyPage",
    name: "KeyPage",
    component: () => import("../views/Pages/KeyPage.vue"),
  },
  {
    path: "/CusProfile",
    name: "CusProfile",
    component: () => import("../views/Customer/CusProfile.vue"),
  },
  {
    path: "/BalancePage",
    name: "BalancePage",
    component: () => import("../views/Reports/BalancePage.vue"),
  },
  {
    path: "/CoverPage",
    name: "CoverPage",
    component: () => import("../views/Reports/CoverPage.vue"),
  },
  {
    path: "/HPPage",
    name: "HPPage",
    component: () => import("../views/Reports/HPPage.vue"),
  },
  {
    path: "/PrizePage",
    name: "PrizePage",
    component: () => import("../views/Reports/PrizePage.vue"),
  },
  {
    path: "/RatePage",
    name: "RatePage",
    component: () => import("../views/Reports/RatePage.vue"),
  },
]

const router = new VueRouter({
  routes
})

export default router

const state = {
  LineList: [],
  PageData: [],
};

const getters = {
  LineList: (state) => state.LineList,
  PageData: (state) => state.PageData,
};

const mutations = {
  setLineList: (state, LineList) => {
    state.LineList = JSON.parse(JSON.stringify(LineList));
    for (let i = 0; i < state.LineList.length; i++) {
      state.LineList[i]["id"] = i + 1;
    }
  },
  setPageData: (state, PageData) => {
    state.PageData = JSON.parse(JSON.stringify(PageData));
  },
  addNewLine: (state, RecentBetLine) => {
    RecentBetLine["id"] = state.LineList.length + 1;
    state.LineList.push(RecentBetLine);
  },
  DeleteLine: (state, LineNumber) => {
    let obj = state.LineList.find((o) => o.LineNumber === LineNumber);
    state.LineList.splice(obj.id - 1, 1);
    for (let i = 0; i < state.LineList.length; i++) {
      state.LineList[i]["id"] = i + 1;
    }
  },
  EditLine: (state, EditBetLine) => {
    console.log("EditBetLine");
    console.log(EditBetLine);

    let obj = state.LineList.find(
      (o) => o.LineNumber === EditBetLine.LineNumber
    );
    console.log(obj);
    state.LineList[obj.id - 1].BetTypeID = EditBetLine.BetTypeID;
    state.LineList[obj.id - 1].Status = EditBetLine.Status;
    state.LineList[obj.id - 1].Number = EditBetLine.Number;
    state.LineList[obj.id - 1].GetPercent = EditBetLine.GetPercent;
    state.LineList[obj.id - 1].PriceText = EditBetLine.PriceText;
  },
};

const actions = {
  TransferData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "get_all":
                  context.commit("setLineList", data.response.LineList);
                  context.commit("setPageData", data.response.PageData);
                  break;
                case "add_line":
                  if (data.response.IsReload == false) {
                    context.commit("addNewLine", data.response.RecentBetLine);
                    context.commit("setPageData", data.response.PageData);
                    console.log("RELOAD FALSE");
                  } else {
                    console.log("RELOAD TRUE");
                  }
                  break;
                case "del_line":
                  if (data.response.IsReload == false) {
                    context.commit("DeleteLine", credentials.LineNumber);
                    context.commit("setPageData", data.response.PageData);
                    console.log("RELOAD FALSE");
                  } else {
                    console.log("RELOAD TRUE");
                  }
                  break;
                case "edit_line":
                  if (data.response.IsReload == false) {
                    context.commit("EditLine", data.response.EditBetLine);
                    context.commit("setPageData", data.response.PageData);
                    console.log("RELOAD FALSE");
                  } else {
                    console.log("RELOAD TRUE");
                  }

                  break;
                default:
                  break;
              }
              resolve(data.response);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const keypage = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

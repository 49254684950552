const state = {
  HPList: [],
  NonSendPage: 0,
  SendPage: 0,
  PriceSumAll: 0,
  PriceLoyAll: 0,
  //   HPGroupRateList: [],
};

const getters = {
  HPList: (state) => state.HPList,
  NonSendPage: (state) => state.NonSendPage,
  SendPage: (state) => state.SendPage,
  PriceSumAll: (state) => state.PriceSumAll,
  PriceLoyAll: (state) => state.PriceLoyAll,
  //   HPGroupRateList: (state) => state.HPGroupRateList,
};

const mutations = {
  // setHPGroupRateList: (state, HPGroupRateList) => {
  //     console.log("HPGroupRateList");
  //     for(let key in HPGroupRateList){
  //         console.log(HPGroupRateList[key]);
  //     }
  // },
  setHPList: (state, HPList) => {
    // console.log("HPList");
    // console.log(HPList);

    let HpPrize = [];
    HPList.forEach((HPListelement) => {
      let isPrize = false;
      //   console.log(HPListelement.Percent);

      //หาว่าคำที่อั้นคืออะไรก่อน
      let PrizeText = "";
      switch (HPListelement.BetTypeDetailID) {
        case "1": //3 บน
          if (HPListelement.Percent == "50") {
            PrizeText = "จ่ายครึ่ง";
          } else if (HPListelement.Percent < 0) {
            PrizeText = "จ่ายตรง " + Math.abs(parseInt(HPListelement.Percent));
            if (
              parseInt(HPListelement.Pay) > 0 &&
              Math.abs(parseInt(HPListelement.Percent)) !=
                parseInt(HPListelement.Pay)
            ) {
              PrizeText += " (จ่ายคนแทง " + HPListelement.Pay + ")";
            }

            if (HPListelement.isExtraType == 1) {
              //มีโต๊ดด้วย
              HPList.forEach((HPListelementTOD) => {
                if (HPListelementTOD.BetTypeDetailID == "2") {
                  if (
                    //เรียงลำดับเลขน้อยไปมาก
                    HPListelement.BetNumber.split("").sort().join("") ==
                    HPListelementTOD.BetNumber.split("").sort().join("")
                  ) {
                    PrizeText +=
                      " โต็ด จ่าย " + Math.abs(HPListelementTOD.Percent);

                    if (
                      HPListelementTOD.Pay > 0 &&
                      Math.abs(HPListelementTOD.Percent) != HPListelementTOD.Pay
                    ) {
                      PrizeText += " (จ่ายคนแทง " + HPListelementTOD.Pay + ")";
                    }
                  }
                }
              });
            }
          } else if (HPListelement.Percent == 0) {
            PrizeText = "ปิดรับ";
          }
          break;
        case "2": // 3โต๊ด
          if (HPListelement.isExtraType == 0) {
            //โต๊ดอย่างเดียวไม่มีตรง
            if (HPListelement.Percent == "50") {
              PrizeText = "จ่ายครึ่ง";
            } else if (HPListelement.Percent < 0) {
              PrizeText =
                "จ่ายบาทละ " + Math.abs(parseInt(HPListelement.Percent));
              if (
                parseInt(HPListelement.Pay) > 0 &&
                Math.abs(parseInt(HPListelement.Percent)) !=
                  parseInt(HPListelement.Pay)
              ) {
                PrizeText += " (จ่ายคนแทง " + HPListelement.Pay + ")";
              }
            } else if (HPListelement.Percent == 0) {
              PrizeText = "ปิดรับ";
            }
          }
          break;
        case "3": //2 บน
          if (HPListelement.Percent == "50") {
            PrizeText = "จ่ายครึ่ง";
          } else if (HPListelement.Percent < 0) {
            PrizeText =
              "จ่ายบาทละ " + Math.abs(parseInt(HPListelement.Percent));
            if (
              parseInt(HPListelement.Pay) > 0 &&
              Math.abs(parseInt(HPListelement.Percent)) !=
                parseInt(HPListelement.Pay)
            ) {
              PrizeText += " (จ่ายคนแทง " + HPListelement.Pay + ")";
            }
          } else if (HPListelement.Percent == 0) {
            PrizeText = "ปิดรับ";
          }
          break;
        case "4": //2 ล่าง
          if (HPListelement.Percent == "50") {
            PrizeText = "จ่ายครึ่ง";
          } else if (HPListelement.Percent < 0) {
            PrizeText =
              "จ่ายบาทละ " + Math.abs(parseInt(HPListelement.Percent));
            if (
              parseInt(HPListelement.Pay) > 0 &&
              Math.abs(parseInt(HPListelement.Percent)) !=
                parseInt(HPListelement.Pay)
            ) {
              PrizeText += " (จ่ายคนแทง " + HPListelement.Pay + ")";
            }
          } else if (HPListelement.Percent == 0) {
            PrizeText = "ปิดรับ";
          }
          break;
        default:
          break;
      }

      //   console.log("P=" + PrizeText);
      HpPrize.forEach((HpPrizeelement) => {
        if (PrizeText == HpPrizeelement.PrizeText) {
          //มี ราคานี้อยู่แล้ว ให้เช็ค bettype ก่อนว่ามีหรือไม่ ถ้ามีให้เพิ่มเลข ไม่มีให้ เพิ่ม bettype ก่อน
          //check bettype
          //   console.log("PrizeText = " + PrizeText);
          let isBetType = false;
          Object.keys(HpPrizeelement.BetType).forEach((key) => {
            // console.log(key, HpPrizeelement.BetType[key]);
            if (
              HpPrizeelement.BetType[key]["BetType"] ==
              HPListelement.BetTypeDetailID
            ) {
              //add nuumber
              HpPrizeelement.BetType[key]["Number"].push(HPListelement);
              isBetType = true;
            } //else add bettype
          }); //end loop find BetType

          if (isBetType == false) {
            //ไม่มี BetType ให้เพิ่มเข้าไป

            let NewBetType = {
              BetType: HPListelement.BetTypeDetailID,
              Number: [],
            };
            HpPrizeelement["BetType"].push(NewBetType);

            HpPrizeelement["BetType"][HpPrizeelement["BetType"].length - 1][
              "Number"
            ].push(HPListelement);
          }

          isPrize = true;
        }
      });

      if (isPrize == false && PrizeText != "") {
        //add HpPrize
        let NewHpPrize = {
          PrizeText: PrizeText,
          BetType: [],
        };
        HpPrize.push(NewHpPrize);
        let NewBetType = {
          BetType: HPListelement.BetTypeDetailID,
          Number: [],
        };
        HpPrize[HpPrize.length - 1]["BetType"].push(NewBetType);

        HpPrize[HpPrize.length - 1]["BetType"][0]["Number"].push(HPListelement);
      }
      //   console.log(HPListelement);
    });
    state.HPList = HpPrize;
    console.log("HPList From store");
    console.log(state.HPList);
  },
  setPageStatus: (state, PageStatus) => {
    state.NonSendPage = 0;
    state.SendPage = 0;
    state.PriceSumAll = 0;
    state.PriceLoyAll = 0;
    PageStatus.forEach((Page) => {
        if(Page.status==0){
            state.NonSendPage = Page.count;
        }
        if(Page.status==1){
            state.SendPage += parseInt(Page.count);
            state.PriceSumAll += parseInt(Page.PriceSumAll);
            state.PriceLoyAll += parseInt(Page.PriceLoyAll);
        }
        if(Page.status==3){
            state.SendPage += parseInt(Page.count);
            state.PriceSumAll += parseInt(Page.PriceSumAll);
            state.PriceLoyAll += parseInt(Page.PriceLoyAll);
        }
        if(Page.status==4){
            state.SendPage += parseInt(Page.count);
            state.PriceSumAll += parseInt(Page.PriceSumAll);
            state.PriceLoyAll += parseInt(Page.PriceLoyAll);
        }
        // ลบ=-1 /รอส่ง=0/ส่งแล้ว=1/ ตีกลับ=2/ถูกรางวัล=3/จ่ายแล้ว=4
      // if (HPListelementTOD.BetTypeDetailID == "2") {
      //   if (
      //     //เรียงลำดับเลขน้อยไปมาก
      //     HPListelement.BetNumber.split("").sort().join("") ==
      //     HPListelementTOD.BetNumber.split("").sort().join("")
      //   ) {
      //     PrizeText +=
      //       " โต็ด จ่าย " + Math.abs(HPListelementTOD.Percent);
      //     if (
      //       HPListelementTOD.Pay > 0 &&
      //       Math.abs(HPListelementTOD.Percent) != HPListelementTOD.Pay
      //     ) {
      //       PrizeText += " (จ่ายคนแทง " + HPListelementTOD.Pay + ")";
      //     }
      //   }
      // }
    });
  },
};

const actions = {
  TransferData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "get_dashboard":
                  console.log("get_dashboard");
                  //   context.commit(
                  //     "setHPGroupRateList",
                  //     data.response.HPGroupRateList
                  //   );
                  context.commit("setHPList", data.response.HPList);
                  context.commit("setPageStatus", data.response.PageStatus);

                  break;
                // case "request_connect":
                //   localStorage.setItem("fun_token", data.response.token);
                //   sessionStorage.setItem("HostID", data.response.HostID);
                //   sessionStorage.setItem("LineID", data.response.LineID);
                //   sessionStorage.setItem("AgentID", data.response.AgentID);
                //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                //   break;
                // case "create_pin":
                //   break;
                // case "check_token":
                //   break;
                // case "check_pin":
                //   sessionStorage.setItem("HostID", data.response.HostID);
                //   sessionStorage.setItem("LineID", data.response.LineID);
                //   sessionStorage.setItem("AgentID", data.response.AgentID);
                //   sessionStorage.setItem("PeriodDT", data.response.PeriodDT);
                //   break;
                default:
                  break;
              }
              resolve(data.response);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const dashboard = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

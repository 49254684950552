import Vue from 'vue'
import Vuex from 'vuex'

import {auth} from './modules/auth'
import {dashboard} from './modules/dashboard'
import {periods} from './modules/periods'
import {pages} from './modules/pages'
import {page} from './modules/page'
import {keypage} from './modules/keypage'
import {cus} from './modules/cus'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    periods,
    dashboard,
    pages,
    page,
    keypage,
    cus,
  }
})

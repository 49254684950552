const state = {
  PagesList: [],
  PageSendCount: 0,
  PriceSumAll: 0,
  PriceLoyAll: 0,
};

const getters = {
  PagesList: (state) => state.PagesList,
  PageSendCount: (state) => state.PageSendCount,
  PriceSumAll: (state) => state.PriceSumAll,
  PriceLoyAll: (state) => state.PriceLoyAll,
};

const mutations = {
  setPagesList: (state, PagesList) => {
    state.PagesList = JSON.parse(JSON.stringify(PagesList));

    state.PageSendCount = 0;
    state.PriceSumAll = 0;
    state.PriceLoyAll = 0;

    PagesList.forEach((element) => {
      //   console.log(element);
      //ลบ=-1 /รอส่ง=0/ส่งแล้ว=1/ ตีกลับ=2/ถูกรางวัล=3/จ่ายแล้ว=4
      if (element.Status == 1 || element.Status == 3 || element.Status == 4) {
        state.PageSendCount++;
        state.PriceSumAll += parseInt(element.PriceSum);
        state.PriceLoyAll += parseInt(element.PriceSumLoy);
      }
    });
  },

  setPageDel: (state, PageNumber) => {
    state.PagesList.find((o) => o.PageNumber === PageNumber)['Status']=-1;
    // state.PagesList.find((o) => o.PageNumber === PageNumber)['Status']=-1;
  },
  
};

const actions = {
  TransferData(context, credentials) {
    console.log(credentials);
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("", credentials, this._vm.$headers)
        .then(({ data, status }) => {
          console.log(data);
          if (status === 200) {
            if (data.message == "Success") {
              switch (credentials.method) {
                case "get_all":
                  console.log("get_all");
                  context.commit("setPagesList", data.response.PageList);
                  break;
                case "del":
                    context.commit("setPageDel", data.response.PageNumber);
                  break;
                default:
                  break;
              }
              resolve(data.response);
            } else {
              reject(data.message);
            }
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  },
};

export const pages = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
